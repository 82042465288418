import { useMemo } from "react";
import { defineMessages } from "react-intl";
import { useParams } from "react-router-dom";
import { Alert, AlertIcon, Heading, Text } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import useAppointmentPhotos from "lib/appointments/useAppointmentPhotos.js";
import useOrder from "lib/order/useOrder.js";
import Loading from "components/layout/Loading.js";
import OrderSummary from "components/order/OrderSummary.js";
import PlaceGroupOrder from "components/order/PlaceGroupOrder.js";
import PlaceAppointmentOrder from "components/order/PlaceAppointmentOrder.js";
import useSession from "../../../../lib/session/useSession.js";

const messages = defineMessages({
  title: { id: "order.title" }
});

function DownloadBlock({ appointment }) {
  if (appointment.downloadFilename) {
    return (
      <Alert status="success">
        <AlertIcon />
        <Text mb="0">
          Your digital downloads are ready!{" "}
          <strong>
            <a
              href={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
                appointment.appointmentUuid
              )}/${encodeURIComponent(appointment.downloadFilename)}`}
            >
              Click Here to Download
            </a>
          </strong>
        </Text>
      </Alert>
    );
  }
  return (
    <Alert status="info">
      <AlertIcon />
      <Text mb="0">
        Thank you for scanning your digital download code!
        <br />
        Your download file is being generated! Please refresh this page in a few
        minutes for the link…
      </Text>
    </Alert>
  );
}

function OrderComplete({ appointment, order, items, photos }) {
  const hasDownload = useMemo(() => {
    for (const item of items) {
      if (
        item.sku === "dnld22" ||
        item.sku === "pckg22a" ||
        item.sku === "pckg22d"
      ) {
        return true;
      }
    }
    return false;
  }, [items]);

  // TODO: Make the submit order status message not hard-coded
  return (
    <>
      <Heading textAlign="center">Your Order</Heading>
      {order.status === "submit" && (
        <Text>
          Your order details are shown below. Order packets will be available
          for pickup from the directors in mid February. Watch your email inbox
          for updates and pickup instructions! Please print this page for your
          records.
        </Text>
      )}
      {order.status === "complete" && (
        <Text>
          Your order packet is now ready for pickup! Please contact your
          organization for available dates and times.
        </Text>
      )}
      <Text>
        Your order number is: <strong>{order.orderNumber}</strong>
      </Text>
      {hasDownload && <DownloadBlock appointment={appointment} />}
      <OrderSummary
        appointment={appointment}
        order={order}
        items={items}
        photos={photos}
      />
    </>
  );
}

function PlaceOrder({ appointment, items, photos }) {
  const sessionResponse = useSession(appointment.sessionUuid);

  if (!sessionResponse.json) {
    return <Loading />;
  }

  switch (sessionResponse.json.session.type) {
    case "group":
      return <PlaceGroupOrder items={items} />;
    case "appoint":
      return (
        <PlaceAppointmentOrder
          appointment={appointment}
          items={items}
          photos={photos}
        />
      );
    default:
      return <p>Unknown session type</p>;
  }
}

function Order() {
  usePageTitle(messages.title);

  const params = useParams();

  const appointmentResponse = useAppointment(params.appointmentUuid);
  const photosResponse = useAppointmentPhotos(params.appointmentUuid);
  const orderResponse = useOrder(params.appointmentUuid, params.orderNumber);

  if (
    !appointmentResponse.json ||
    !photosResponse.json ||
    !orderResponse.json
  ) {
    return <Loading />;
  }

  if (
    appointmentResponse.status !== 200 ||
    photosResponse.status !== 200 ||
    orderResponse.status !== 200
  ) {
    return <Text>Not found</Text>;
  }

  if (orderResponse.json.order.status === "cart") {
    return (
      <PlaceOrder
        appointment={appointmentResponse.json.appointment}
        items={orderResponse.json.items}
        photos={photosResponse.json.photos}
      />
    );
  }

  return (
    <OrderComplete
      appointment={appointmentResponse.json.appointment}
      order={orderResponse.json.order}
      items={orderResponse.json.items}
      photos={photosResponse.json.photos}
    />
  );
}

export default Order;
