import { useLayoutEffect, useMemo, useRef } from "react";
import { defineMessages, FormattedMessage, FormattedNumber } from "react-intl";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import usePageTitle from "lib/ui/usePageTitle.js";
import useAppointment from "lib/appointments/useAppointment.js";
import useAppointmentPhotos from "lib/appointments/useAppointmentPhotos.js";
import useOrder from "lib/order/useOrder.js";
import Loading from "components/layout/Loading.js";

const messages = defineMessages({
  title: { id: "order.add-pictures" },
});

function PhotoBlock({ appointment, items, photo }) {
  const { state } = useLocation();

  const photoBlockRef = useRef();

  const photoItems = useMemo(() => {
    return items.filter((item) => {
      return item.photoFilename === photo.filename;
    });
  }, [items, photo]);

  useLayoutEffect(() => {
    if (state?.photoFilename === photo.filename) {
      photoBlockRef.current.scrollIntoView();
    }
  }, [state, photo.filename]);

  return (
    <Flex
      py="3"
      alignItems="center"
      direction={{ base: "column", sm: "row" }}
      ref={photoBlockRef}
    >
      <Link to={photo.filename}>
        <Box
          as="span"
          display="inline-block"
          backgroundImage={`https://pictureday.s3.us-west-2.amazonaws.com/appointments/${encodeURIComponent(
            appointment.appointmentUuid
          )}/${encodeURIComponent(photo.filename)}`}
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          height="250px"
          width="250px"
        />
      </Link>
      <Box pt={{ base: "5", sm: "0" }} pl={{ base: "0", sm: "5" }}>
        {photo.title && (
          <Heading as="h3" size="md">
            {photo.title}
          </Heading>
        )}
        {photoItems.map((item) => (
          <Text key={item.sku}>
            <FormattedNumber
              value={item.unitPrice * item.quantity}
              style="currency"
              currency="USD"
            />{" "}
            ({item.quantity}) — {item.name}
          </Text>
        ))}
        <Button as={Link} to={photo.filename} colorScheme="gray">
          <FormattedMessage
            id={
              photoItems.length < 1
                ? "order.add-this-picture"
                : "order.update-quantity"
            }
          />
        </Button>
      </Box>
    </Flex>
  );
}

function PhotosList({ appointment, items, photos }) {
  const navigate = useNavigate();

  const likedPhotos = useMemo(() => {
    const likedPhotos = [];
    if (photos) {
      for (const photo of photos) {
        if (photo.reviewStatus === "like") {
          likedPhotos.push(photo);
        } else {
          for (const item of items) {
            if (
              item.photoFilename === photo.filename ||
              item.photoFilename === photo.filename
            ) {
              likedPhotos.push(photo);
              break;
            }
          }
        }
      }
    }
    return likedPhotos;
  }, [items, photos]);

  const handleClick = () => {
    navigate("../../confirm");
  };

  return (
    <>
      {likedPhotos.map((photo) => (
        <PhotoBlock
          key={photo.filename}
          appointment={appointment}
          items={items}
          photo={photo}
        />
      ))}
      <Center>
        <Button type="button" colorScheme="blue" onClick={handleClick}>
          Review My Order!
        </Button>
      </Center>
    </>
  );
}

function AddPictures() {
  usePageTitle(messages.title);

  const params = useParams();

  const appointmentResponse = useAppointment(params.appointmentUuid);
  const photosResponse = useAppointmentPhotos(params.appointmentUuid);
  const orderResponse = useOrder(params.appointmentUuid, params.orderNumber);

  if (
    !appointmentResponse.json ||
    !photosResponse.json ||
    !orderResponse.json
  ) {
    return <Loading />;
  }

  if (
    appointmentResponse.status !== 200 ||
    photosResponse.status !== 200 ||
    orderResponse.status !== 200
  ) {
    return <Text>Not found</Text>;
  }

  return (
    <>
      <Heading textAlign="center">
        <FormattedMessage id="order.add-pictures" />
      </Heading>
      <Text>
        Online ordering will close at midnight{" "}
        <Text as="strong">Saturday, February 1!</Text> Order packets will be
        available for pickup from the directors in mid February. Watch your
        email inbox for updates and pickup instructions.
      </Text>
      <Text>
        Select as many pictures as you like! When you’re ready, scroll to the
        bottom of this page and click the{" "}
        <Text as="strong">Review My Order!</Text> button to review and confirm
        your order.
      </Text>
      <PhotosList
        appointment={appointmentResponse.json.appointment}
        items={orderResponse.json.items}
        photos={photosResponse.json.photos}
      />
    </>
  );
}

export default AddPictures;
